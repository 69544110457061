function Intro() {
    return (
        <div className="Intro-wrapper">
            <div className="Intro">
                <h1 className="Intro-header">Rebekah Marvin</h1>
                <h2 className="Intro-header-2">Pianist</h2>
            </div>
        </div>
    )
}

export default Intro;